import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    IconButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    Avatar,
    Tooltip
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload'; // Importando o ícone de upload
import axios from 'axios';

function LeadCard({ lead, onViewDetails, onStatusChange, disabled }) {
    // Estados
    const [status, setStatus] = useState('');
    const [timeLeft, setTimeLeft] = useState(null);
    const [showAdditionalPhones, setShowAdditionalPhones] = useState(false);
    const [mensagens, setMensagens] = useState([]); // Armazena as mensagens do chat
    const [openModal, setOpenModal] = useState(false); // Controla a abertura da modal
    const [novaMensagem, setNovaMensagem] = useState(''); // Armazena a nova mensagem a ser enviada
    const [hasNewMessage, setHasNewMessage] = useState(false); // Indica se há novas mensagens
    const [ultimaMensagem, setUltimaMensagem] = useState(null); // Armazena a última mensagem enviada

    const [uploadingAudio, setUploadingAudio] = useState(false); // Indica se o áudio está sendo enviado
    const [audioFile, setAudioFile] = useState(null); // Armazena o arquivo de áudio selecionado

    const messagesEndRef = useRef(null); // Referência para rolar para a última mensagem
    const currentUser = localStorage.getItem('username'); // Obter o username do usuário atual

    // Função para rolar para a última mensagem
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Função para lidar com a expiração do status 'negociando'
    const handleExpiration = useCallback(async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://apil2.wh.tec.br/base_sp/clear', {
                leadId: lead.id
            }, {
                headers: {
                    'Authorization': token
                }
            });

            if (response.status === 200) {
                onStatusChange(lead.id, '');
            } else {
                console.error('Falha ao atualizar após expiração:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao tentar atualizar após expiração:', error);
        }
    }, [lead.id, onStatusChange]);

    // Função para personalizar a mensagem substituindo placeholders
    const personalizeMessage = (message) => {
        return message
            .replace('{{nome}}', lead.nome_cliente)
            .replace('{{processo}}', lead.processo)
            .replace('{{cpf}}', lead.cpf)
            .replace('{{email}}', lead.email)
            .replace('{{valorEntrada}}', lead.valor_entrada)
            .replace('{{marcaModelo}}', lead.marca_modelo)
            .replace('{{anoVeiculo}}', lead.ano_veiculo)
            .replace('{{corVeiculo}}', lead.cor_veiculo)
            .replace('{{chassi}}', lead.chassi)
            .replace('{{renavam}}', lead.renavam)
            .replace('{{placa}}', lead.placa);
    };

    // Função para obter uma mensagem aleatória, sem repetir a última
    const getRandomMessage = () => {
        const allMessages = mensagens.filter(msg => msg.messageType === 'text');
        if (allMessages.length === 0) return '';

        let novaMsg = '';
        do {
            const randomIndex = Math.floor(Math.random() * allMessages.length);
            novaMsg = allMessages[randomIndex].mensagem;
        } while (novaMsg === ultimaMensagem && allMessages.length > 1);

        setUltimaMensagem(novaMsg);
        return novaMsg;
    };

    // Função para enviar mensagem via WhatsApp (texto)
    const sendWhatsAppMessage = async () => {
        const telefoneComDDI = `55${lead.telefone_principal}`; // Acrescenta o 55 ao telefone principal
        const mensagemAleatoria = personalizeMessage(getRandomMessage()); // Seleciona uma mensagem aleatória e personaliza

        try {
            const response = await axios.post('https://send.wh.tec.br/send-message', { // Mantendo o envio de texto via API antiga
                number: telefoneComDDI,
                message: mensagemAleatoria
            });

            if (response.status === 200) {
                console.log('Mensagem enviada com sucesso.');
                // Atualiza o status para "Enviado" após o sucesso
                handleStatusChange('enviado');
            }
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
        }
    };

    // Função para enviar mensagem para telefones adicionais (texto)
    const sendWhatsAppMessageTo = async (phone) => {
        const telefoneComDDI = `55${phone}`; // Acrescenta o 55 ao telefone
        const mensagemAleatoria = personalizeMessage(getRandomMessage()); // Seleciona uma mensagem aleatória e personaliza

        try {
            const response = await axios.post('https://send.wh.tec.br/send-message', { // Mantendo o envio de texto via API antiga
                number: telefoneComDDI,
                message: mensagemAleatoria
            });

            if (response.status === 200) {
                console.log(`Mensagem enviada com sucesso para ${phone}.`);
                // Opcional: Atualizar o status ou fazer algo adicional
            }
        } catch (error) {
            console.error(`Erro ao enviar mensagem para ${phone}:`, error);
        }
    };

    // Função para alterar o status da lead
    const handleStatusChange = async (newStatus) => {
        setStatus(newStatus);

        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://apil2.wh.tec.br/base_sp/status', { // Atualizando para a nova API
                leadId: lead.id,
                status: newStatus
            }, {
                headers: {
                    'Authorization': token
                }
            });

            if (response.status === 200) {
                onStatusChange(lead.id, newStatus);
            } else {
                console.error('Falha ao atualizar status:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao tentar atualizar status:', error);
        }
    };

    // Função para enviar uma nova mensagem digitada pelo usuário (texto)
    const handleSendNewMessage = async () => {
        const telefoneComDDI = `55${lead.telefone_principal}`; // Acrescenta o 55 ao telefone principal

        try {
            const response = await axios.post('https://send.wh.tec.br/send-message', { // Mantendo o envio de texto via API antiga
                number: telefoneComDDI,
                message: novaMensagem
            });

            if (response.status === 200) {
                console.log('Nova mensagem enviada com sucesso.');
                // Adiciona a nova mensagem no chat
                setMensagens([...mensagens, { mensagem: novaMensagem, enviadaPeloUsuario: true, timestamp: new Date().toISOString(), messageType: 'text' }]);
                setNovaMensagem(''); // Limpa o campo de entrada após o envio
                setHasNewMessage(false); // Remove a notificação de nova mensagem
                scrollToBottom(); // Rola para a última mensagem
            }
        } catch (error) {
            console.error('Erro ao enviar a nova mensagem:', error);
        }
    };

    // Função para enviar uma nova mensagem de áudio
    const handleAudioUpload = async () => {
        if (!audioFile) {
            alert('Por favor, selecione um arquivo de áudio para enviar.');
            return;
        }

        const formData = new FormData();
        formData.append('audio', audioFile);
        formData.append('telefone', lead.telefone_principal);
        formData.append('ptt', false); // Ajuste conforme necessário
        formData.append('seconds', 0); // Você pode implementar a extração real da duração
        formData.append('pushName', currentUser);
        formData.append('owner', currentUser);

        setUploadingAudio(true); // Inicia o indicador de upload

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('https://msg.wh.tec.br/mensagens/audio', formData, { // Enviando para a nova API
                headers: {
                    Authorization: token,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                alert('Áudio enviado com sucesso!');
                setAudioFile(null); // Limpa o estado do arquivo
                fetchMensagens(); // Atualiza a lista de mensagens após o envio
                scrollToBottom(); // Rola para a última mensagem
            }
        } catch (error) {
            console.error('Erro ao enviar áudio:', error);
            alert('Falha ao enviar o áudio.');
        } finally {
            setUploadingAudio(false); // Finaliza o indicador de upload
        }
    };

    // Função para formatar o tempo restante
    const formatTimeLeft = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const days = Math.floor(totalSeconds / (60 * 60 * 24));
        const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
        const seconds = totalSeconds % 60;
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    // Função para alternar a exibição de telefones adicionais
    const toggleAdditionalPhones = () => {
        setShowAdditionalPhones(!showAdditionalPhones);
    };

    // Função para renderizar um telefone adicional
    const renderPhone = (label, phone, isWhatsApp) => {
        if (!phone) return null;
        return (
            <Typography variant="body1" key={label} sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <strong>{label}:</strong>&nbsp;{phone}
                {isWhatsApp && (
                    <Tooltip title={`Enviar mensagem via WhatsApp para ${phone}`}>
                        <IconButton size="small" onClick={() => sendWhatsAppMessageTo(phone)} sx={{ marginLeft: '8px' }}>
                            <WhatsAppIcon color="success" />
                        </IconButton>
                    </Tooltip>
                )}
            </Typography>
        );
    };

    /**
     * Função para buscar todas as mensagens (texto e áudio) a partir da nova API
     */
    const fetchMensagens = useCallback(async () => {
        const token = localStorage.getItem('token');
        const consultorName = localStorage.getItem('username');

        try {
            const response = await axios.get(`https://msg.wh.tec.br/mensagens/55${lead.telefone_principal}`, { // Usando a nova API
                headers: {
                    Authorization: token,
                    'consultor-name': consultorName
                }
            });

            const mensagensRecebidas = response.data.mensagens;

            if (Array.isArray(mensagensRecebidas)) {
                const mensagensFormatadas = mensagensRecebidas.map(msg => ({
                    ...msg,
                    enviadaPeloUsuario: msg.enviadaPeloUsuario === 'Sim' ? true : false,
                    // Garantir que o tipo MIME seja 'audio/mpeg' para áudio ou manter o tipo original para texto
                    mimetype: msg.messageType === 'audioMessage' ? 'audio/mpeg' : undefined
                }));

                // Ordena as mensagens por timestamp
                mensagensFormatadas.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                setMensagens(mensagensFormatadas);
            } else {
                console.error('Erro: O formato de resposta não é um array de mensagens.');
                setMensagens([]);
            }
        } catch (error) {
            console.error('Erro ao buscar mensagens:', error);
        }
    }, [lead.telefone_principal]);

    /**
     * Função para abrir a modal e carregar as mensagens
     */
    const handleViewMessages = async () => {
        try {
            await fetchMensagens();
            setOpenModal(true);
            setHasNewMessage(false);
            scrollToBottom();
        } catch (error) {
            console.error('Erro ao carregar as mensagens:', error);
            setMensagens([]);
        }
    };

    // Efeito para buscar mensagens personalizadas e lidar com o tempo de expiração
    useEffect(() => {
        setStatus(lead.status || '');

        fetchMensagens();

        // Se o status for 'negociando', calcular o tempo até expirar
        if (lead.status === 'negociando' && lead.expiracao) {
            const expiracaoDate = new Date(lead.expiracao);
            const now = new Date();
            const timeRemaining = expiracaoDate - now;

            if (timeRemaining > 0) {
                setTimeLeft(timeRemaining);

                const intervalId = setInterval(() => {
                    const timeLeftCalc = expiracaoDate - new Date();
                    if (timeLeftCalc <= 0) {
                        clearInterval(intervalId);
                        // Limpar campos após expiração
                        handleExpiration();
                    } else {
                        setTimeLeft(timeLeftCalc);
                    }
                }, 1000);

                return () => clearInterval(intervalId);
            } else {
                // Caso já tenha expirado
                handleExpiration();
            }
        }
    }, [lead.status, lead.expiracao, handleExpiration, fetchMensagens]); // Incluído fetchMensagens nas dependências

    // Implementação do Polling para verificar novas mensagens
    useEffect(() => {
        const intervalId = setInterval(async () => {
            try {
                const token = localStorage.getItem('token');
                const consultorName = localStorage.getItem('username');

                const response = await axios.get(`https://msg.wh.tec.br/mensagens/55${lead.telefone_principal}`, { // Usando a nova API
                    headers: {
                        Authorization: token,
                        'consultor-name': consultorName
                    }
                });

                const mensagensRecebidas = response.data.mensagens;

                if (Array.isArray(mensagensRecebidas)) {
                    const mensagensFormatadas = mensagensRecebidas.map(msg => ({
                        ...msg,
                        enviadaPeloUsuario: msg.enviadaPeloUsuario === 'Sim' ? true : false,
                        mimetype: msg.messageType === 'audioMessage' ? 'audio/mpeg' : undefined
                    }));

                    // Ordena as mensagens por timestamp
                    mensagensFormatadas.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                    // Verifica se há novas mensagens
                    if (mensagensFormatadas.length > mensagens.length) {
                        setMensagens(mensagensFormatadas);

                        const newMessage = mensagensFormatadas[mensagensFormatadas.length - 1];
                        if (!openModal && !newMessage.enviadaPeloUsuario) {
                            setHasNewMessage(true);
                        }
                    }
                }
            } catch (error) {
                console.error('Erro ao verificar novas mensagens:', error);
            }
        }, 5000); // Verifica a cada 5 segundos

        return () => clearInterval(intervalId);
    }, [lead.telefone_principal, mensagens, openModal]);

    // Função para renderizar a modal com as mensagens
    const renderChatModal = () => (
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ backgroundColor: '#075E54', color: '#fff', display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ marginRight: '8px', bgcolor: '#128C7E' }}>
                    {lead.nome_cliente.charAt(0).toUpperCase()}
                </Avatar>
                Chat com {lead.nome_cliente}
            </DialogTitle>
            <DialogContent dividers sx={{ padding: '16px', backgroundColor: '#ECE5DD' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '400px',
                        overflowY: 'auto',
                        padding: '8px',
                        backgroundColor: '#ECE5DD',
                        borderRadius: '8px'
                    }}
                >
                    {mensagens.length > 0 ? (
                        mensagens.map((msg, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: msg.enviadaPeloUsuario ? 'flex-end' : 'flex-start',
                                    marginBottom: '8px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: msg.enviadaPeloUsuario ? 'row-reverse' : 'row',
                                        maxWidth: '70%'
                                    }}
                                >
                                    <Avatar sx={{ bgcolor: msg.enviadaPeloUsuario ? '#DCF8C6' : '#075E54', margin: '0 8px' }}>
                                        {msg.enviadaPeloUsuario ? 'U' : 'L'}
                                    </Avatar>
                                    <Box
                                        sx={{
                                            padding: '10px',
                                            borderRadius: '10px',
                                            backgroundColor: msg.enviadaPeloUsuario ? '#DCF8C6' : '#FFFFFF',
                                            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {msg.messageType === 'audioMessage' ? (
                                            <audio controls>
                                                <source src={msg.mensagem} type={msg.mimetype || 'audio/mpeg'} />
                                                Seu navegador não suporta o elemento de áudio.
                                            </audio>
                                        ) : (
                                            <Typography variant="body1">{msg.mensagem}</Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            Nenhuma mensagem encontrada.
                        </Typography>
                    )}
                    <div ref={messagesEndRef} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px', backgroundColor: '#ECE5DD', display: 'flex', alignItems: 'center' }}>
                <TextField
                    variant="outlined"
                    placeholder="Digite uma mensagem"
                    fullWidth
                    value={novaMensagem}
                    onChange={(e) => setNovaMensagem(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            if (novaMensagem.trim()) {
                                handleSendNewMessage();
                            }
                        }
                    }}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '20px',
                        marginRight: '8px'
                    }}
                />
                <IconButton
                    color="primary"
                    onClick={handleSendNewMessage}
                    disabled={!novaMensagem.trim()}
                    sx={{
                        padding: '10px',
                        backgroundColor: '#128C7E',
                        '&:hover': {
                            backgroundColor: '#075E54',
                        },
                        color: '#fff'
                    }}
                >
                    <SendIcon />
                </IconButton>

                {/* Botão para enviar áudio */}
                <IconButton
                    color="primary"
                    component="label"
                    sx={{
                        padding: '10px',
                        backgroundColor: '#128C7E',
                        '&:hover': {
                            backgroundColor: '#075E54',
                        },
                        color: '#fff',
                        marginLeft: '8px',
                        opacity: uploadingAudio ? 0.6 : 1,
                        pointerEvents: uploadingAudio ? 'none' : 'auto'
                    }}
                >
                    <Tooltip title="Enviar Áudio">
                        <UploadIcon />
                    </Tooltip>
                    <input
                        type="file"
                        accept="audio/*"
                        hidden
                        onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                setAudioFile(e.target.files[0]);
                                handleAudioUpload();
                            }
                        }}
                    />
                </IconButton>
            </DialogActions>
        </Dialog>
    );

    return (
        <Card
            sx={{
                borderRadius: '16px',
                backgroundColor: hasNewMessage ? '#1dd1a1' : (disabled ? '#1a1a1a' : '#242424'), // Altera a cor se houver nova mensagem
                color: '#fff',
                padding: '16px',
                marginBottom: '16px',
                opacity: disabled ? 0.7 : 1,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s ease, transform 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.02)',
                },
            }}
        >
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize', fontWeight: '600', color: '#e0e0e0' }}>
                            {lead.nome_cliente}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#999" }}>
                            {lead.processo}
                        </Typography>
                    </Grid>

                    {status === 'negociando' && timeLeft && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: '#ff8c00' }}>
                                Tempo restante: {formatTimeLeft(timeLeft)}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ fontWeight: '600', color: '#fff', display: 'flex', alignItems: 'center' }}>
                            Celular: {disabled ? 'xxxx-xxxx' : lead.telefone_principal}
                            {!disabled && lead.whatsapp && (
                                <Tooltip title="Enviar mensagem via WhatsApp">
                                    <IconButton size="small" onClick={sendWhatsAppMessage} sx={{ marginLeft: '8px' }}>
                                        <WhatsAppIcon color="success" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            onClick={toggleAdditionalPhones}
                            sx={{
                                cursor: 'pointer',
                                color: '#ff8c00',
                                fontWeight: 'bold',
                                textDecoration: 'none'
                            }}
                        >
                            {showAdditionalPhones ? 'Ocultar Telefones' : 'Ver Mais Telefones'}
                        </Link>
                    </Grid>

                    {showAdditionalPhones && (
                        <>
                            {renderPhone("Telefone Adicional 1", lead.telefone_adicional1, lead.telefone_adicional1_whatsapp)}
                            {renderPhone("Telefone Adicional 2", lead.telefone_adicional2, lead.telefone_adicional2_whatsapp)}
                            {renderPhone("Telefone Adicional 3", lead.telefone_adicional3, lead.telefone_adicional3_whatsapp)}
                            {renderPhone("Telefone Adicional 4", lead.telefone_adicional4, lead.telefone_adicional4_whatsapp)}
                            {renderPhone("Telefone Adicional 5", lead.telefone_adicional5, lead.telefone_adicional5_whatsapp)}
                        </>
                    )}

                    <Grid item xs={9}>
                        <FormControl fullWidth>
                            <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Status da Lead</InputLabel>
                            <Select
                                value={status}
                                onChange={(event) => handleStatusChange(event.target.value)}
                                label="Status da Lead"
                                displayEmpty
                                disabled={disabled}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.5)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#fff',
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>Selecione</em>
                                </MenuItem>
                                <MenuItem value="enviado">Enviado</MenuItem>
                                <MenuItem value="negociando">Negociando</MenuItem>
                                <MenuItem value="nao responde">Não Responde</MenuItem>
                                <MenuItem value="venda realizada">Venda Realizada</MenuItem>
                                <MenuItem value="resolveu com o banco">Resolveu com o banco</MenuItem>
                                <MenuItem value="fechou com outra assessoria">Fechou com outra assessoria</MenuItem>
                                <MenuItem value="nao e a pessoa">Número de telefone errado</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3} sx={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#3043ec',
                                color: '#fff',
                                borderRadius: '50%',
                                width: '50px',
                                height: '50px',
                                minWidth: '50px',
                                minHeight: '50px',
                                '&:hover': {
                                    backgroundColor: '#ff8c00',
                                },
                            }}
                            onClick={() => onViewDetails(lead)}
                            disabled={disabled}
                        >
                            <InfoIcon />
                        </Button>
                    </Grid>

                    {/* Botão para abrir a modal */}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={handleViewMessages}
                            disabled={disabled}
                            sx={{ 
                                position: 'relative',
                                backgroundColor: hasNewMessage ? '#ff6666' : 'primary.main', // Alterar a cor se houver nova mensagem
                                '&:hover': {
                                    backgroundColor: hasNewMessage ? '#ff4d4d' : 'primary.dark',
                                },
                            }}
                        >
                            Ver Mensagens
                            {hasNewMessage && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: '#ff0000',
                                        borderRadius: '50%',
                                    }}
                                />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>

            {/* Modal do chat */}
            {renderChatModal()}
        </Card>
    ); // Fechamento correto do return com ')'

} // Fechamento da função LeadCard

export default LeadCard;
